<template>
  <div class="col-lg-12">
    <div class="lesiurpackages_hotelsbox domestic_hotelsbox" :class="isBig && 'big'">
      <img :src="imageUrl" width="" height="" :alt="item.hotelName" v-bind="mainProps"
          onerror="this.src='assets/img/lesiurpackages_hotelsimg6.jpg'" />

      <div class="packages-top d-flex align-items-center">
        <span class="tag">{{ calcNight(item.checkoutDate, item.checkinDate) }} {{ $t("home.nights") }}</span>
        <span class="tag" v-if="isBadge">{{item.badgeText}}</span>
        <span class="tag" v-if="(isOdysseySite && isFCLAgent && discountPercent > 0 && !isBadge) || (!isOdysseySite && discountPercent > 0 && !isBadge)">
          {{discountPercent + memberDiscountPercent}}% {{ $t("home.discount") }}
        </span>
      </div>

      <div class="packages-bottom">
        <div class="packages-bottom-top">
          <div class="top-title d-flex justify-content-between align-items-center">
            <div class="hotel-name"> {{ item.hotelName }}, {{ item.extendedProperties.destination.name }}</div>
            <div class="duration">{{ changeDateFormat(item.checkinDate) }} - {{changeDateFormat(item.checkoutDate) }} <i class="fa fa-calendar-alt"></i></div>
            <div class="remark" v-if="remark"><i class="far fa-comment-dots"></i> {{ remark }} </div>
          </div>
          <div class="price-title"> {{$t("home.total-price-for-the-package")}} </div>
          <div class="bottom-title d-flex justify-content-between align-items-center">
            <span class="price"><sub>₪</sub>{{ discountedPrice }}</span>
            <a v-if="isLanding" :href="productPageLink">
              <div class="btn-book">{{ $t("home.quick-order") }}</div>
            </a>
            <router-link v-else :to="productPageLink">
              <div class="btn-book">{{ $t("home.quick-order") }}</div>
            </router-link>
          </div>
        </div>
        <div class="footerbg">{{ item.priceRemark }}</div>
      </div>
    </div>
  </div>
</template>
<script>

import TemplateLesiurtabItem from './TemplateLesiurtabItem';

export default {
  name: 'LesiurtabItemTheme2',
  mixins: [TemplateLesiurtabItem],
  components: {
  },
};
</script>

<style scoped>
.lesiurpackages_hotelsbox .packages-top{
  box-sizing: border-box;
  padding: 15px;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
}
.lesiurpackages_hotelsbox .packages-top span.tag {
  background: linear-gradient(90deg, rgb(251 169 53) 0%, rgba(231,135,0,1) 75%);
  border-radius: 13.5px;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
  box-sizing: border-box;
  padding: 5px 10px;
  margin-left: 5px;
}
.lesiurpackages_hotelsbox .packages-bottom{
  box-sizing:border-box;
  position:absolute;
  width:100%;
  bottom:0;
  z-index:4;
}
.lesiurpackages_hotelsbox .packages-bottom .footerbg {
  background: #FF9500;
  box-sizing: border-box;
  padding: 10px 5px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
}
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top{
  box-sizing:border-box;
  padding:15px;
}
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top .top-title {
  border-bottom: 1px solid rgb(255 255 255 / 52%);
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex !important;
  flex-direction: column;
  align-items: flex-start !important;
}
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top .hotel-name{
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 10px;
  color: #FFFFFF;
}
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top span.price{
  font-weight: 700;
  font-size: 30px;
  line-height: 18px;
  color: #FFFFFF;
}
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top span.price sub {
  bottom: 0em;
}
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top .duration,
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top .remark {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
  direction: ltr;
  margin: 0;
  text-align: right;
  white-space: nowrap;
}
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top .remark {
  white-space: normal;
  direction: rtl;
  line-height: 10px;
}
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top .duration i{
  margin-right:4px
}
.lesiurpackages_hotelsbox .packages-bottom .price-title{
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
  direction: ltr;
  margin: 0;
  text-align: right;
  white-space: nowrap;
}
.lesiurpackages_hotelsbox .packages-bottom .packages-bottom-top .bottom-title .btn-book {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #FFFFFF;
  border-radius: 34px;
  text-transform: uppercase;
  background-color:#FF0000;
  direction: ltr;
  border: none;
  padding: 7px 12px;
}
.lesiurpackages_hotelsbox:hover .packages-bottom .packages-bottom-top .bottom-title .btn-book{
  background-color: #fff;
  color: #000;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox img {
  filter: brightness(0.8);
}

.domestic_hotelsbox.lesiurpackages_hotelsbox:hover img {
  filter: brightness(1);
}

@media (max-width: 991px) {
}

@media (min-width: 480px) {
}
</style>
